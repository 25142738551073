.App {
  text-align: center;
  width: fit-content;
}
@keyframes waveMotion {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  25% {
    transform: rotate(-10deg) translateY(-5px);
  }
  50% {
    transform: rotate(0deg) translateY(0);
  }
  75% {
    transform: rotate(10deg) translateY(5px);
  }
  100% {
    transform: rotate(0deg) translateY(0);
  }
}

.animate-wave {
  display: inline-block;
  animation: waveMotion 2s infinite ease-in-out;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */



.scrollbar-thin {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #4A5568 #E2E8F0;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: #E2E8F0; /* Lighter background */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #4A5568; /* Thumb color */
  border-radius: 9999px; /* Full round corners */
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: #2D3748; /* Darker thumb on hover */
}